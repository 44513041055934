<template>  
  <div>
   <v-btn-toggle
    v-model="toggle_exclusive"
    mandatory
    style="width:100%;overflow-x:scroll !important"
  >
    <v-btn @click="getData('all')">            
      <v-icon>mdi-file-document-multiple</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.all")}}</span>
    </v-btn>
    <v-btn @click="getData('today')">
      <v-icon>mdi-clipboard-file-outline</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.today")}}</span>
    </v-btn>
    <v-btn @click="getData('last_day')">
      <v-icon>mdi-file-account</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.last_day")}}</span>
    </v-btn>
    <v-btn @click="getData('this_week')">
      <v-icon>mdi-file-clock</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.this_week")}}</span>
    </v-btn>
    <v-btn @click="getData('last_week')">
      <v-icon>mdi-file-edit</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.last_week")}}</span>
    </v-btn>
    <v-btn @click="getData('this_month')">
      <v-icon>mdi-file-cog</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.this_month")}}</span>
    </v-btn>
      <v-btn @click="getData('last_month')">
      <v-icon>mdi-file-code-outline</v-icon>
      <span style="font-family:'khmer mef1'">{{$t("button.last_month")}}</span>
    </v-btn>
  </v-btn-toggle>

  <v-row class="mt-3">
    <v-col cols="4" sm="4" md="4" lg="4">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
       
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-col cols="4" sm="4" md="4" lg="4">
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        label="End Date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        >
      </v-text-field>
    </template>
      <v-date-picker
        v-model="endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="1" sm="1" md="1" lg="1">
      <v-btn 
        class="white--text btn-custom mt-4"
        depressed
        block
       @click="getData('search')"
       style="font-family:'khmer mef1';background-color:#003366; color:#ffffff"
      >
        {{$t("button.search")}}
      </v-btn>
    </v-col>
  </v-row>
    
  </div>
</template>

<script>
import * as moment from 'moment';
export default {
  props: ["darkmode", "status", "params"],
  data() {
    return {
      data: {
        startDate:  null,
        endDate: null,
      },
      startDate:  null,
      endDate:  null,
      v_startDate: null,
      v_endDate: null,
    };
  },
 watch: {
    data: function() {
      // Emit this information to the parents component
      if(this.startDate != null && this.endDate != null){
        this.$emit("child-checkbox", this.data);
      }
    }
  },
  methods: {
   async getData(type) {
      if (type == 'all') {
        this.startDate = "";
        this.endDate = "";
        this.data.startDate =""
        this.data.endDate =""
        this.$emit("child-checkbox", this.data);  
      }
      if (type == 'today') {
        let _today = new Date().toISOString().slice(0, 10)
        this.startDate = _today;
        this.endDate = _today;
        this.data.startDate =_today
        this.data.endDate =_today
        this.$emit("child-checkbox", this.data);  
      }
      else if (type == 'last_day') {
        let last_day = new Date();
        last_day.setDate(last_day.getDate() - 1);
        last_day =last_day.toISOString().slice(0, 10)
        this.startDate = last_day;
        this.endDate = last_day;
        this.data.startDate =last_day;
        this.data.endDate =last_day;
        this.$emit("child-checkbox", this.data);
      }
      else if (type == 'this_week') {
        let startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
        let endOfWeek   = moment().endOf('isoWeek').format('YYYY-MM-DD');
        this.startDate = startOfWeek;
        this.endDate = endOfWeek;
        this.data.startDate =startOfWeek;
        this.data.endDate =endOfWeek;
        this.$emit("child-checkbox", this.data);   
      }
      else if (type == 'last_week') {
        let startOfLastWeek = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');  
        let endOfLastWeek   = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        this.startDate = startOfLastWeek;
        this.endDate = endOfLastWeek;
        this.data.startDate =startOfLastWeek;
        this.data.endDate =endOfLastWeek;
        this.$emit("child-checkbox", this.data);   
      }
      else if (type == 'this_month') {
        let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        let endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        this.startDate = startOfMonth;
        this.endDate = endOfMonth;
        this.data.startDate =startOfMonth;
        this.data.endDate =endOfMonth;
        this.$emit("child-checkbox", this.data);   
      }
      else if (type == 'last_month') {
        let startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');  
        let endOfLastMonth   = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        this.startDate = startOfLastMonth;
        this.endDate = endOfLastMonth;
        this.data.startDate =startOfLastMonth;
        this.data.endDate =endOfLastMonth;
        this.$emit("child-checkbox", this.data);   
      }
      else if (type == 'search'){
        this.data.startDate =this.startDate
        this.data.endDate =this.endDate
        if(this.startDate != null && this.endDate != null){
          this.$emit("child-checkbox", this.data);
        }   
      }  
    },
  },
};
</script>
